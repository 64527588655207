<template>  

  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        Planes - agregar o editar
      </v-card-title>
    </v-card>

    <DataTable
      id="planes-table"
      title="Planes"
      subtitle="Planes registrados"
      :headers="headers"
      :items="planesTable.items"
      :loading="tableLoading"
      :toolbarActions="true"
      :toolbarTitle="false"
      :sortBy="['estatus']"
      :sortDesc="[true, false]"
      actionsAlign="center"
      showItemPropToDelete="nombre"
      @editItem="planesTable.editItem"
      @deleteItem="planDelete"
    >
      <template slot="toolbarActions">
        <!-- Para que un ref tome efecto el nodo debe existir en el DOM (tambien se toma si esta oculto), 
        en este caso los componentes dentro de Dialog no existen hasta que la accion planesTable.dialog 
        es true -->
        <v-form ref="form" v-model="validFormPlanes">
          <Dialog
            useToolbarStyle
            v-model="planesTable.dialog"
            btnText="Agregar Plan"
            :title="`${planesTable.formTitle} plan`"
            :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            @onCloseToolbarBtn="planesTable.dialog = false"
          >
            <template slot="content">
                <div v-if="planesTable.isEditable && planesTable.editedItem.user_logs" class="my-2"> 
                  <b>
                    Plan capturado por: 
                  </b>
                  {{ planesTable.editedItem.user_logs.nombre }} 
                  {{ planesTable.editedItem.user_logs.apellidos }},
                  el
                  {{ $dayjs(planesTable.editedItem.user_logs.fecha_log).format("DD/MMM/YYYY [a las] h:mm a.") }}
                </div>

                <v-row>
                  <v-col cols="12" sm="6" md="12"> 
                    <VTextField
                      @keyup.enter="planSave"
                      v-model="planesTable.editedItem.nombre"
                      :rules="[FormValidations.required]"
                      label="Nombre del plan"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      @keyup.enter="planSave"
                      v-model="planesTable.editedItem.min_anios"
                      :rules="[FormValidations.required, FormValidations.onlyInteger, FormValidations.nonZero]"
                      label="Min. años"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      @keyup.enter="planSave"
                      v-model="planesTable.editedItem.max_anios"
                      label="Max. años (opcional)"
                    ></VTextField>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-if="planesTable.isEditable" cols="6" sm="6" md="8"> 
                    <v-switch
                      v-model="planesTable.editedItem.estatus"
                      color="red darken-3"
                      :style="{ maxWidth: 'max-content' }"
                      :prepend-icon="`${planesTable.editedItem.estatus ? 'mdi-package-down' : 'mdi-package-up'}`"
                      :label="`${planesTable.editedItem.estatus ? 'Estatus: baja' : 'Estatus: alta'}`"
                    />
                  </v-col>
                </v-row>
            </template>

            <template slot="actions">
              <!-- <Button text @click="planesTable.close">Cancelar</Button> -->
                
              <Button 
                text 
                :loading="tableLoading"
                :disabled="!validFormPlanes || planesTable.disableActionBtn" 
                @click="planSave"
              >
                Guardar
              </Button>
            </template>
          </Dialog> 
        </v-form>
      </template>
    </DataTable>

  </v-container>

</template>

<script>
import Button from "@/components/ui/Button";
import Dialog from "@/components/ui/Dialog";
import DataTable from "@/components/ui/DataTable";
import useDataTable from "@/composables/useDataTable";
import { getCurrentInstance, ref, watch, onBeforeUpdate } from "vue";
import { APP_DEBUG_MODE as APP_DEBUG } from "@/utils/Constants";
import FormValidations from "@/utils/FormValidations";
import VCurrencyField from "@/components/ui/VCurrencyField";
import { addUserLogToSendToDB } from "@/utils/Functions";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Button,
    Dialog,
    DataTable,
  },
  setup(props, ctx) {
    const vm = getCurrentInstance();
    const { APP, PLANES, USERS } = STORE_NAMESPACES;

    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    const headers = [
      { text: "Nombre", value: "nombre" },
      { text: "Min. años", value: "min_anios" },
      { text: "Max. años", value: "max_anios" },
      { text: "Estatus", value: "estatus", sortable: false },
      { text: "Acciones", value: "action", align: 'center', sortable: false },
    ];

    const defaultValuesOnOpen = {};

    const logged_user = getters[USERS].findById(state[APP].idUser);
    const tableLoading = getters[APP].getLoading;
    const dataSource = getters[PLANES].getAll; // Se obtienen con getter por inicializarse en App.vue
    const planesTable = useDataTable(dataSource, defaultValuesOnOpen);

    const validFormPlanes = ref(true);

    // Resetear validacion cuando se abre el dialog/modal
    watch(() => planesTable.dialog, (dialog) => {
      if (dialog) {
        vm.proxy.$refs.form.resetValidation();
      }
    });

    function planSave() {
      const planData = {...planesTable.editedItem};
      
      planesTable.save(isEditable => {
        if (isNaN(+planData.max_anios) || +planData.max_anios < 1) {
          planData.max_anios = null;
        }

        // Datos para registrar log de usuario al insertar un nuevo plan
        if (!isEditable) {
          addUserLogToSendToDB(planData, logged_user.value, "PlanRegistro");
        }

        APP_DEBUG_MODE && console.log("Datos plan a enviar: ", planData);
        const actionMethod = isEditable ? "UPDATE" : "INSERT";
        actions[PLANES][actionMethod](planData, () => planesTable.close());
      });
    }

    function planDelete(planData) {
      actions[PLANES].DELETE(planData, () => planesTable.close());
    }

    return {
      headers,
      planesTable,
      planSave,
      validFormPlanes,
      tableLoading,
      planDelete,

      // Funcs. de validacion
      FormValidations,
    };
  }
};
</script>

